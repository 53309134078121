import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "recherche",
      "style": {
        "position": "relative"
      }
    }}>{`Recherche`}<a parentName="h1" {...{
        "href": "#recherche",
        "aria-label": "recherche permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Cette page est destinée à toutes les ressources intéressantes que notre communauté a rassemblées et qui ne sont pas spécifiquement des outils, mais qui méritent votre attention.`}</p>
    <h2 {...{
      "id": "videos",
      "style": {
        "position": "relative"
      }
    }}>{`Videos`}<a parentName="h2" {...{
        "href": "#videos",
        "aria-label": "videos permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`La vidéo est un `}<a parentName="p" {...{
        "href": "/learn/module-3/remember/#extending-our-mnemonics"
      }}>{`moyen mnémotechnique plus efficace que le texte`}</a>{`. Les deux canaux non-crypto qui, selon nous, illustrent le mieux cette situation sont les suivants `}<a parentName="p" {...{
        "href": "https://www.youtube.com/c/3blue1brown/featured"
      }}>{`3blue1brown`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCs7nPQIEba0T3tGOWWsZpJQ"
      }}>{`Like Stories of Old`}</a>{`.`}</p>
    <p>{`Les chaînes YouTube auxquelles nous renvoyons ci-dessous ne sont pas nécessairement des recherches au sens traditionnel du terme. Ils sont beaucoup plus axés sur la création de sens, qui est en soi un `}<a parentName="p" {...{
        "href": "https://joelightfoot.substack.com/p/the-liminal-web-mapping-an-emergent"
      }}>{`phénomène moderne fascinant à étudier`}</a>{`. Ces canaux figurent sur cette page non pas en raison de leur rigueur, mais pour vous suggérer que la recherche peut évoluer d'une manière que les universitaires ne peuvent tout simplement pas comprendre, et qu'il existe des moyens de faire de la recherche en public (tout comme de coder en public) qui peuvent être extrêmement bénéfiques, tant pour vous en tant que créateur que pour le grand public que vous servez.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Whiteboard Crypto`}</strong></p>
          <p>{`L'une des meilleures chaînes YouTube pour les concepts généraux de crypto.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://www.youtube.com/channel/UCsYYksPHiGqXHPoHI-fm5sg" mdxType="Button">
  Regarder
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Finematics`}</strong></p>
          <p>{`L'autre meilleure chaîne YouTube pour les concepts généraux de la crypto.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://www.youtube.com/c/Finematics/videos" mdxType="Button">
  Regarder
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Simply Explained`}</strong></p>
          <p>{`Il s'agit d'une chaîne YouTube perspicace qui illustre de nombreux points de l'introduction ci-dessus.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://youtu.be/OcmvMs4AMbM?t=273" mdxType="Button">
  Regarder
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "lecture",
      "style": {
        "position": "relative"
      }
    }}>{`Lecture`}<a parentName="h2" {...{
        "href": "#lecture",
        "aria-label": "lecture permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`La recherche se résume souvent à l'analyse d'un grand nombre d'informations. Cette section, c'est pour vous donne l'opportunité de devenir bon pour ça.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Short Overview`}</strong></p>
          <p>{`Une liste succincte et perspicace de ressources, par ordre chronologique`}</p>
          <Button to="https://danromero.org/crypto-reading/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Timing
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Token Economy`}</strong></p>
          <p>{`One of the OG resources, begun before 2015.`}</p>
          <p><em parentName="p">{`H/T `}<Link to="https://www.reddit.com/r/ethereum/comments/o4unlp/ama_we_are_the_efs_research_team_pt_6_23_june_2021/" mdxType="Link">{`EF Research Team`}</Link></em></p>
          <Button to="https://medium.com/token-kitchen/publishing-token-economy-on-github-under-cc-by-nc-sa-ec569008a5dd" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Plonger
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Other Internet`}</strong></p>
          <p>{`Une collaboration fascinante entre plusieurs grands esprits. Commencer avec `}<Link to="https://otherinter.net/research/squad-wealth/" mdxType="Link">{`Squad Wealth`}</Link>{`. Compléter avec:`}</p>
          <Button to="https://otherinter.net/research/positive-sum-worlds/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Bons publics
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`How to DAO`}</strong></p>
          <p>{`Un cours de quatre semaines, plus que gratuit, sur l'avenir de la coopération, créé par Stephen Reid.`}</p>
          <Button to="https://docs.google.com/document/d/1jxbb3YkrjAT1TUe6W2yCFUAsXUhdVt5JYoJwmMfykoQ/edit#heading=h.xm43iia8mx4f" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Coopérer
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Web3 Fundamentals`}</strong></p>
          <p>{`Un cours vraiment fantastique fourni gratuitement par des amis à nous.`}</p>
          <p><em parentName="p">{`H/T John`}</em></p>
          <Button to="https://github.com/londonblockchainlabs/web3-fundamentals" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  S'amusez
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Bitcoin Information`}</strong></p>
          <p>{`Les listes de ressources Bitcoin de Jameson Lopp.`}</p>
          <Button to="https://www.lopp.net/bitcoin-information.html" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Etudier
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Coinstory`}</strong></p>
          <p>{`Un livre qui retrace l'histoire et l'évolution des crypto-monnaies, écrit par un membre de Kernel.`}</p>
          <p><em parentName="p">{`H/T David Stancel`}</em></p>
          <Button to="https://coinstory.tech/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Découvrir
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Job Resources`}</strong></p>
          <p>{`Un excellent guide gratuit qui nous vous l'assurons, est toujours d'actualité.`}</p>
          <p><em parentName="p">{`H/T Jo-Ann Hamilton`}</em></p>
          <Button to="https://medium.com/coinmonks/free-resources-to-get-a-blockchain-job-in-6-months-or-less-for-non-coders-5ddee72fbc7b" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Laissez-vous guider
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`WEF Toolkit`}</strong></p>
          <p>{`L'ultime boîte à outils de l'entreprise. Très sérieux ;)`}</p>
          <p><em parentName="p">{`H/T Gyan`}</em></p>
          <Button to="https://tinyurl.com/wef-blockchain-tools" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Boîte à outils
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "climat",
      "style": {
        "position": "relative"
      }
    }}>{`Climat`}<a parentName="h2" {...{
        "href": "#climat",
        "aria-label": "climat permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Cette section sera étoffée dans les blocs à venir, à mesure qu'il apparaîtra plus clairement où se trouvent les contributions et les recherches les plus utiles.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`ReFi Podcast`}</strong></p>
          <p>{`Conversations avec un climat changeant.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://refipodcast.podbean.com/" mdxType="Button">
  Lire
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`KlimaDAO on Bankless`}</strong></p>
          <p>{`Une merveilleuse conversation sur la coordination mondiale.`}</p>
          <Button to="https://youtu.be/uM5XX4AwEuI" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Ecouter
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Toucan Protocol`}</strong></p>
          <p>{`Cultiver l'intendance planétaire.`}</p>
          <Button to="https://mirror.xyz/0x84F5590Ffe54e0f684b845807D036D8C1D18e684/FqIc41yUQF4Jw-ppjJh1x28Wh143D2CjG1fGk3sGzWc" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Lire la suite
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "securité",
      "style": {
        "position": "relative"
      }
    }}>{`Securité`}<a parentName="h2" {...{
        "href": "#securit%C3%A9",
        "aria-label": "securité permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Bien qu'il existe de merveilleux outils et des sources d'information déjà répertoriés sur la page sécurité de cette section, la sécurisation du code sur les réseaux publics et conflictuel a nécessité - et continuera de nécessiter - un grand nombre de recherches révolutionnaires. Nous présentons ici quelques-uns des sujets les plus intéressants que nous avons abordés dans Kernel.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Smashing Smart Contracts for Fun and Profit`}</strong></p>
          <p>{`L'article original de Bernhard sur la façon de tirer profit de l'amusement et l'outil d'analyse de sécurité Mythril.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://github.com/b-mueller/smashing-smart-contracts/blob/master/smashing-smart-contracts-1of1.pdf" mdxType="Button">
  Symboliser
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Automated Tooling`}</strong></p>
          <p>{`Une liste fascinante de ressources de sécurité de Johan qui, malgré notre aversion pour ces choses, est trop bonne pour ne pas être incluse ici.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://www.notion.so/Module-2-Automated-Tooling-22b7b1513dd247d685616d55e27b6877" mdxType="Button">
  Subir une mutation
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "zero-knowledge",
      "style": {
        "position": "relative"
      }
    }}>{`Zero Knowledge`}<a parentName="h2" {...{
        "href": "#zero-knowledge",
        "aria-label": "zero knowledge permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Il s'agit probablement de l'aspect du monde de la cryptographie qui a fait l'objet du plus grand nombre de recherches dans le domaine des blockchains. Nous ne présentons ici que quelques-unes des ressources de plus en plus nombreuses qui sont disponibles pour vous aider à comprendre, puis à mettre en œuvre vos propres systèmes de connaissance zéro.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`ZeroKnowledge FM`}</strong></p>
          <p>{`Le podcast d'Anna Rose rassemble des présentations sur des sujets de connaissance zéro provenant de différentes équipes et devrait servir d'excellente entrée en matière pour des recherches plus techniques.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://www.zeroknowledge.fm/" mdxType="Button">
  Profiter
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`zku.one`}</strong></p>
          <p>{`Une expérience d'apprentissage complète qui couvre une partie théorique et beaucoup de pratique.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://zku.one/course-schedule" mdxType="Button">
  Lire
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Matter Labs`}</strong></p>
          <p>{`C'est la "zkSNARKs bible".`}</p>
          <p><em parentName="p">{`H/T Luka`}</em></p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://github.com/matter-labs/awesome-zero-knowledge-proofs" mdxType="Button">
  Découvrir
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Industry Overview`}</strong></p>
          <p>{`Un article détaillé de l'équipe zcash, avec de nombreux liens vers des recherches plus approfondies.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://z.cash/technology/zksnarks/" mdxType="Button">
  Comprendre
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Academic Overview`}</strong></p>
          <p>{`Une explication étonnamment simple, avec des exemples. `}<a parentName="p" {...{
              "href": "https://github.com/AztecProtocol/AZTEC/blob/develop/AZTEC.pdf"
            }}>{`AZTEC Protocol’s paper`}</a>{` de Zachary J. Williamson est également pertinent ici.`}</p>
          <p><em parentName="p">{`H/T Roshan Raghupathy`}</em></p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://arxiv.org/pdf/1906.07221.pdf" mdxType="Button">
  Comprendre
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`PLONK`}</strong></p>
          <p>{`Profitez de deux pour le prix d'un avec le papier PLONK de l'équipe AZTEC et `}<Link to="https://vitalik.eth.limo/general/2019/09/22/plonk.html" mdxType="Link">{`commentaires de Vitalik`}</Link>{`.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://eprint.iacr.org/2019/953.pdf" mdxType="Button">
  Plonger
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "spreadsheets",
      "style": {
        "position": "relative"
      }
    }}>{`Spreadsheets`}<a parentName="h2" {...{
        "href": "#spreadsheets",
        "aria-label": "spreadsheets permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Si vous vous êtes imprégné de nos listes "distate of Awesome", vous pouvez probablement deviner ce que nous pensons des feuilles de calcul. Cependant, il y a toujours des exceptions qui confirment la règle, et nous en listons ici quelques-unes pour des domaines spécifiques qui pourraient vous intéresser.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Token Economics Resource List`}</strong></p>
          <p>{`Tout ce que vous avez toujours voulu savoir sur les jetons, et probablement beaucoup de choses que vous ne savez pas.`}</p>
          <Button to="https://docs.google.com/spreadsheets/d/1_py70Ic2u91VILJWCA7AMOXVZmmTv-zGo-LPr3PhDvM/edit#gid=2065938169" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Plus profond encore
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`All NFT Marketplaces`}</strong></p>
          <p>{`Tiré de cette `}<Link to="https://github.com/gianni-dalerta/awesome-nft" mdxType="Link">{`liste impressionnante`}</Link>{`.`}</p>
          <Button to="https://docs.google.com/spreadsheets/d/1Idy9TBXcWk6VpbASscXHSORaoaz0YR_arBvKRDu6xOI/edit#gid=0" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Recherche
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`All Messenger Protocols`}</strong></p>
          <p>{`Une feuille de calcul perspicace pour tous ceux qui travaillent sur les messages.`}</p>
          <p><em parentName="p">{`H/T Dhruv Malik`}</em></p>
          <Button to="https://docs.google.com/spreadsheets/d/1-UlA4-tslROBDS9IqHalWVztqZo7uxlCeKPQ-8uoFOU/edit#gid=0" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Diffuser
          </Button>
        </Flex>
      </Chocolate>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      